// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-about-page-tsx": () => import("./../../../src/templates/about-page.tsx" /* webpackChunkName: "component---src-templates-about-page-tsx" */),
  "component---src-templates-index-page-tsx": () => import("./../../../src/templates/index-page.tsx" /* webpackChunkName: "component---src-templates-index-page-tsx" */),
  "component---src-templates-products-page-tsx": () => import("./../../../src/templates/products-page.tsx" /* webpackChunkName: "component---src-templates-products-page-tsx" */),
  "component---src-templates-theory-page-tsx": () => import("./../../../src/templates/theory-page.tsx" /* webpackChunkName: "component---src-templates-theory-page-tsx" */)
}

